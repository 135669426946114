import React, { useState } from 'react';
import { updateUserSettings } from '../../actions/setting-action';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATION_POPUP } from '../../actions/types';

const NotificationPopup = () => {
    const [dontShowAgain, setDontShowAgain] = useState(false);
    //const [tempPopupOff, setTempPopupOff] = useState(false);
    const tempPopupOff = useSelector((state)=>state.settings.notificationPopup);
    const dispatch = useDispatch();

    const handleClose = (dontShowAgain) => {
        if (dontShowAgain) {
            // Call the API and update state
            dispatch(updateUserSettings('notification_popup', dontShowAgain));
        }
        dispatch({
            type: NOTIFICATION_POPUP,
            payload: 1,
        });
        //setTempPopupOff(true); // Close the popup locally
    };

    const handleUpdateSettings = (newValue) => {
        // Dispatch action to update user settings
        
    };

    const handleCheckboxChange = () => {
        setDontShowAgain(!dontShowAgain);
    };

    if (tempPopupOff === 1) {
        return null; // Don't render the popup if the user has opted out
    }
    if (tempPopupOff === undefined) {
        return null; // Wait until the value is loaded
    }

    return (
       
        <div style={styles.overlay}>
            <div style={styles.popup}>
                <button style={styles.closeButton} onClick={()=>handleClose(false)}>
                    &times;
                </button>
                <h2>Important Update</h2>
                <p>
                    Starting <strong>January 2025</strong>, CryptoHero will no longer offer a free plan.
                    To learn more about this update, please visit our blog for details.
                </p>
                <a
                    href="https://blog.cryptohero.ai/discontinuation-of-cryptoheros-free-lite-plan/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                >
                    Read More
                </a>
                <div style={styles.checkboxContainer}>
                    <label htmlFor="dontShowAgain" onClick={()=>handleClose(1)} className='pointer'>Don't show this again</label>
                </div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    popup: {
        background: '#fff',
        borderRadius: '8px',
        width: '400px',
        padding: '20px',
        textAlign: 'center',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    },
    link: {
        color: '#007BFF',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    checkboxContainer: {
        marginTop: '15px',
    },
};

export default NotificationPopup;
