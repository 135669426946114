import React, { useEffect } from "react";
import {
  CHANGE_BO_TYPE,
  CHANGE_EO,
  CHANGE_FREQUENCY,
} from "../../../../actions/create-bot-type";
import BooleanView from "../BooleanView";
import SliderView from "../SliderView";
import TutorialPopup from "../../../help/TutorialPopup";
import { useDispatch, useSelector } from "react-redux";
import { Frequencies, FrequencyValues,FrequencyValuesSimple,FrequenciesSimple,BotType, SUB_TIER, FrequencyValuesPro, FrequenciesPro } from "../../../../Const";
import { checkSub, isMaster, isPro } from "../../../../utils/SubUtils";
import LeverageSlider from "../LeverageSlider";
import MaxPositionSize from "../MaxPositionSize";
import MarginModeModule from "../futures/modules/MarginModeModule";
import OrderTypeModule from "../futures/modules/OrderTypeModule";
import PriceTypeModule from "../futures/modules/PriceTypeModule";
import BaseOrderLimitModule from "../futures/modules/BaseOrderLimitModule";
import MinimumPriceGapModule from "../MinimumPriceGapModule";

const FutureNormalParamsView = (props) => {
  const { newBot,t,editing,balance,currentCoin} = props;
  const orderType = newBot.baseOrderType;
  const extraOrders = newBot.extraOrderNum;
  const frequency = newBot.frequency;
  const baseOrder = newBot.baseOrder;
  const fund = newBot.initFund;
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const { subPremium, subTier } = checkSub(subscription);
  const tradingFreqValues = (isPro(subscription) || isMaster(subscription))?(newBot.botType==BotType.simple?FrequencyValues:FrequencyValuesPro):(newBot.botType==BotType.simple?FrequencyValuesSimple:FrequencyValues)
  const tradingFreqencies = (isPro(subscription) || isMaster(subscription))?(newBot.botType==BotType.simple?Frequencies:FrequenciesPro):(newBot.botType==BotType.simple?FrequenciesSimple:Frequencies)
  
  const isExitBot = newBot.botType==BotType.exit;
  const isSimpleBot = newBot.botType==BotType.simple;
  const dispatch = useDispatch();
  const orderTypeChanged = (val) => {
    dispatch({
      type: CHANGE_BO_TYPE,
      payload: val,
    });
  };

  const extraOrdersChanged = (val) => {
    dispatch({
      type: CHANGE_EO,
      payload: val,
    });
  };

  const frequencyChanged = (val) => {
    dispatch({
      type: CHANGE_FREQUENCY,
      payload: val,
    });
  };
  const formatExtraOrder = (value) => {
    if (value == 0) {
      return t("disable");
    }
    return `${value} (${(100 - baseOrder) / value}%)`;
  };
  const formartFrequency = (value) => tradingFreqencies[value];
  
  return (
    <div>
      <LeverageSlider 
      step={1}
      min={5}
      max={125}
      t={t}
      newBot={newBot}
      editing={editing}
      >
      </LeverageSlider>
      <div style={{ marginTop: 20 }}>
      <MaxPositionSize 
        newBot={newBot} 
        editing={editing} 
        fund={fund} 
        currentCoin={currentCoin} 
        balance={balance}
        t={t}>
      </MaxPositionSize>
      </div>
      <div style={{ marginTop: 20 }}>
        <MarginModeModule 
          newBot={newBot} 
          editing={editing} 
          t={t}></MarginModeModule>
      </div>
      <div style={{ marginTop: 20 }}>
        <OrderTypeModule 
          newBot={newBot} 
          editing={editing}  
          t={t}></OrderTypeModule>
      </div>
      <div style={{ marginTop: 20 }}>
        <PriceTypeModule 
          newBot={newBot} 
          editing={editing} 
          t={t}></PriceTypeModule>
      </div>
      <div style={{ marginTop: 20 }}>
        <BaseOrderLimitModule 
          newBot={newBot} 
          editing={editing} 
          fund={fund}
          currentCoin={currentCoin}
          t={t}></BaseOrderLimitModule>
      </div>
      <div style={{ marginTop: 20 }}>
      {!isExitBot &&<BooleanView
          title={t("bo-type")}
          firstButton="static"
          secondButton="dynamic"
          selected={orderType}
          onSelect={orderTypeChanged}
          tutorial={<TutorialPopup content={t("base-order-type-help")} />}
        />}
      </div>
      <div style={{ marginTop: 20 }}>
      {!isExitBot && <SliderView
          title={t("eo")}
          min={0}
          max={isSimpleBot?10:30}
          value={extraOrders}
          formatLabel={formatExtraOrder}
          onChange={extraOrdersChanged}
          tutorial={<TutorialPopup content={t("extra-order-help")} />}
        /> }
      </div>
      <div style={{ marginTop: 20 }}>
        <SliderView
          title={t("tf")}
          max={tradingFreqValues.length - 1}
          value={tradingFreqValues.indexOf(frequency)}
          formatLabel={formartFrequency}
          onChange={(value) => frequencyChanged(tradingFreqValues[value])}
          tutorial={<TutorialPopup content={t("trading-freq-help")} />}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        {!isSimpleBot ? <><MinimumPriceGapModule newBot={newBot} t={t}></MinimumPriceGapModule></>:<></>}
      </div>
    </div>
  );
};

export default FutureNormalParamsView;
