import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_LEVERAGE } from "../../../actions/create-bot-type";
import { BotType } from "../../../Const";
import TutorialPopup from "../../help/TutorialPopup";
import SliderView from "./SliderView";

const LeverageSlider = (props)=>{
    const {min,max,step,formatLabel,t,newBot,editing} = props;
    const dispatch = useDispatch();
    const defaultLeverage = newBot.botType == BotType.simple?2:10;
    console.log(newBot.leverage,min,max)
    const maxLeverage = newBot.botType == BotType.simple?5:125;
    const formartLeverage = (value) => value+"x";
    useEffect(() => {
        if (!editing && newBot.leverage === undefined) {
          leverageChanged(defaultLeverage);
        }
    }, [max]);
    const leverageChanged = (val) => {
        dispatch({
          type: CHANGE_LEVERAGE,
          payload: val,
        });
      };
    
    if (newBot.leverage === undefined) {
        return null; // Wait until the value is loaded
    }
    return(
    <>
        <SliderView
            title={t("leverage")}
            step={1}
            min={1}
            max={maxLeverage}
            formatLabel={formartLeverage}
            onChange={leverageChanged}
            value={newBot.leverage}
            tutorial={<TutorialPopup content={t("leverage-help")} />}
        />
    </>
    )
}
export default LeverageSlider