import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_START,
  SIGN_OUT,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  SIGN_UP_START,
  SIGN_UP_REDIRECT,
  RESET_SIGNIN,
  RESET_SIGNUP,
  UPDATE_PASS_ERROR,
  UPDATE_PASS_START,
  UPDATE_PASS_SUCCESS,
  UPDATE_PASS_RESET,
  UPDATE_CURRENCY_SUCCESS,
  GET_OTP_ERROR,
  GET_OTP_RESET,
  GET_OTP_START,
  GET_OTP_SUCCESS,
  RESET_PASS_ERROR,
  RESET_PASS_START,
  RESET_PASS_SUCCESS,
  RESET_PASS_RESET,
  VERIFY_OTP_SUCCESS,
  UPDATE_USER_ROLE,
  UPDATE_GA_SETTING,
  SELECT_EXCHANGE_ID,
  SELECTED_THEME,
  FUTURE_UI,
} from "./types";
import axios from "axios";
import {
  login,
  signUpUrl,
  updatePassUrl,
  getOtpUrl,
  resetPassUrl,
  getRegisterOtpUrl,
  verifyOtpUrl,
} from "./const";
import { host } from "../config";
import network from "./network";
import { getExchangePairs, getIndicators, getInterArbitragePairs, getIntraArbitragePairs } from "./bot-action";
import {  getUserAllSettings, getUserSettings, userSetting } from "./setting-action";
import Cookies from "universal-cookie";

export const signInGoogle = (tokenId, referral_code,appSumoCode) => (dispatch) => {
  dispatch({
    type: SIGN_IN_START,
  });
  var loginParams = {
    id_token: tokenId,
    grant_type: "password",
    client_secret: "qVGXVkGNnSEkGZpvnWOkUjoAX1snVN5uRfb7ABOD",
    client_id: 4,
    device_id: "123",
    platform: "web",
    from: "web",
    referral_code
  }
  if(appSumoCode){
    loginParams = {...loginParams,...appSumoCode}
  }
  axios
    .post(host + login, loginParams)
    .then((res) => {
      handleSignIn(res, dispatch);
    })
    .catch((err) => {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: String(err),
      });
    });
};

export const signInFacebook =
  (accessToken, userId, referral_code,appSumoCode) => (dispatch) => {
    dispatch({
      type: SIGN_IN_START,
    });
    var loginParams = {
      facebook_id: userId,
      facebook_token: accessToken,
      grant_type: "password",
      client_secret: "qVGXVkGNnSEkGZpvnWOkUjoAX1snVN5uRfb7ABOD",
      client_id: 4,
      device_id: "123",
      platform: "web",
      from: "web",
      referral_code,
    }
    if(appSumoCode){
      loginParams = {...loginParams,...appSumoCode}
    }
    axios
      .post(host + login,loginParams )
      .then((res) => {
        handleSignIn(res, dispatch);
      })
      .catch((err) => {
        dispatch({
          type: SIGN_IN_ERROR,
          payload: String(err),
        });
      });
  };

export const signInApple = (idToken, referral_code) => (dispatch) => {
  dispatch({
    type: SIGN_IN_START,
  });
  axios
    .post(host + login, {
      identity_token: idToken,
      grant_type: "password",
      client_secret: "qVGXVkGNnSEkGZpvnWOkUjoAX1snVN5uRfb7ABOD",
      client_id: 4,
      device_id: "123",
      platform: "web",
      from: "web",
      referral_code,
    })
    .then((res) => {
      handleSignIn(res, dispatch);
    })
    .catch((err) => {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: String(err),
      });
    });
};

export const signIn = (email, pass,extraParams) => (dispatch) => {
  dispatch({
    type: SIGN_IN_START,
  });
  var loginParams = {
    username: email,
    password: pass,
    grant_type: "password",
    client_secret: "qVGXVkGNnSEkGZpvnWOkUjoAX1snVN5uRfb7ABOD",
    client_id: 4,
    device_id: "123",
    platform: "web",
    from: "web",
  };
  if(extraParams){
    loginParams = {...loginParams,...extraParams}
  }
  axios
    .post(host + login, loginParams)
    .then((res) => {
      handleSignIn(res, dispatch);
    })
    .catch((err) => {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: String(err),
      });
    });
};

const handleSignIn = (res, dispatch) => {
  const { code, message, access_token, user } = res.data;
  if (code === 200) {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: { access_token, user },
    });
    dispatch({
      type: UPDATE_CURRENCY_SUCCESS,
      payload: user.currency,
    });
    dispatch({
      type: SELECT_EXCHANGE_ID,
      payload: user.current_exchange,
    });
    dispatch({
      type: UPDATE_USER_ROLE,
      payload: user.role,
    });
    dispatch({
      type: UPDATE_GA_SETTING,
      payload: user.google2fa_setting != null && user.google2fa_setting != 0,
    });
    dispatch({
      type: SELECTED_THEME,
      payload: user.theme?user.theme:'light',
    });

    preLoadData(dispatch, user);
  } else {
    dispatch({
      type: SIGN_IN_ERROR,
      payload: message,
    });
  }
};

const preLoadData = (dispatch, user) => {
  const cookies = new Cookies();
  if(user.selected_exchange){
    dispatch({
      type: FUTURE_UI,
      payload: user.selected_exchange.is_future,
    });
  }
  dispatch(getExchangePairs(user.current_exchange || 0));
  dispatch(getIntraArbitragePairs(user.current_exchange || 0));
  dispatch(getInterArbitragePairs(user.current_exchange || 0));
  dispatch(getIndicators());
  dispatch(getUserSettings("taketour"));
  dispatch(getUserAllSettings("notification_popup"));
  dispatch(userSetting("language",cookies.get('current_lang')));
};

export const signUp = (params) => (dispatch) => {
  dispatch({
    type: SIGN_UP_START,
  });
  const { first_name, email, password, password_confirmation } =
    params;

  if (first_name == null || first_name === "") {
    return dispatch({
      type: SIGN_UP_ERROR,
      payload: "Please input first name",
    });
  }
  
  if (email == null || email === "") {
    return dispatch({
      type: SIGN_UP_ERROR,
      payload: "Please input email",
    });
  }

  if (password !== password_confirmation) {
    return dispatch({
      type: SIGN_UP_ERROR,
      payload: "Passwords don't match.",
    });
  }

  axios
    .post(host + signUpUrl, params)
    .then((res) => {
      const { code, message, access_token, user } = res.data;
      if (code == 200) {
        dispatch({
          type: SIGN_UP_SUCCESS,
          payload: {
            access_token,
            user,
          },
        });
        preLoadData(dispatch, user);
        dispatch({
          type: UPDATE_CURRENCY_SUCCESS,
          payload: user.currency,
        });
        setTimeout(() => {
          dispatch({
            type: SIGN_UP_REDIRECT,
          });
        }, 1000);
      } else {
        dispatch({
          type: SIGN_UP_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SIGN_UP_ERROR,
        payload: String(err),
      });
    });
};
export const bitGetsignUp = (params) => (dispatch) => {
  dispatch({
    type: SIGN_UP_START,
  });
  const {  email, bitget_account } =
    params;

  if (bitget_account == null || bitget_account === "") {
    return dispatch({
      type: SIGN_UP_ERROR,
      payload: "Please input Bitget account ID",
    });
  }
  if (email == null || email === "") {
    return dispatch({
      type: SIGN_UP_ERROR,
      payload: "Please input email",
    });
  }

  axios
    .post(host + signUpUrl, params)
    .then((res) => {
      const { code, message, access_token, user } = res.data;
      if (code == 200) {
        dispatch({
          type: SIGN_UP_SUCCESS,
          payload: {
            access_token,
            user,
          },
        });
        preLoadData(dispatch, user);
        dispatch({
          type: UPDATE_CURRENCY_SUCCESS,
          payload: user.currency,
        });
        setTimeout(() => {
          dispatch({
            type: SIGN_UP_REDIRECT,
          });
        }, 1000);
      } else {
        dispatch({
          type: SIGN_UP_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SIGN_UP_ERROR,
        payload: String(err),
      });
    });
};

export const signOut = () => (dispatch) => {
  dispatch({
    type: SIGN_OUT,
  });
};

export const resetSignIn = () => (dispatch) => {
  dispatch({
    type: RESET_SIGNIN,
  });
};

export const resetSignUp = () => (dispatch) => {
  dispatch({
    type: RESET_SIGNUP,
  });
};

export const changePassword = (params) => (dispatch, getState) => {
  const { old_password, password, password_confirmation } = params;
  if (old_password == "" || password == "" || password_confirmation == "") {
    return dispatch({
      type: UPDATE_PASS_ERROR,
      payload: "Password can not be empty.",
    });
  } else if (password != password_confirmation) {
    return dispatch({
      type: UPDATE_PASS_ERROR,
      payload: "Passwords don't match.",
    });
  }

  dispatch({ type: UPDATE_PASS_START });

  network
    .post(updatePassUrl, params, dispatch, getState)
    .then((res) => {
      const { code, message } = res.data;
      if (code == 200) {
        dispatch({ type: UPDATE_PASS_SUCCESS });
        setTimeout(() => {
          dispatch(signOut());
        }, 500);
      } else {
        dispatch({
          type: UPDATE_PASS_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_PASS_ERROR,
        payload: String(err),
      });
    });
};

export const resetUpdatePass = () => (dispatch) => {
  dispatch({
    type: UPDATE_PASS_RESET,
  });
};

export const getOtp = (email) => (dispatch) => {
  dispatch({
    type: GET_OTP_START,
  });
  axios
    .get(host + getOtpUrl, { params: { email } })
    .then((res) => {
      const { code, message } = res.data;
      if (code == 200) {
        dispatch({
          type: GET_OTP_SUCCESS,
          payload: email,
        });
      } else {
        dispatch({
          type: GET_OTP_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_OTP_ERROR,
        payload: String(err),
      });
    });
};

export const resetOtpPage = () => (dispatch) => {
  dispatch({
    type: GET_OTP_RESET,
  });
};

export const resetPassword = (params) => (dispatch) => {
  dispatch({
    type: RESET_PASS_START,
  });
  axios
    .post(host + resetPassUrl, params)
    .then((res) => {
      const { code, message } = res.data;
      if (code == 200) {
        dispatch({
          type: RESET_PASS_SUCCESS,
        });
      } else {
        dispatch({
          type: RESET_PASS_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET_PASS_ERROR,
        payload: String(err),
      });
    });
};

export const resetNewPassPage = () => (dispatch) => {
  dispatch({
    type: RESET_PASS_RESET,
  });
};

export const getRegisterOtp = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .get(getRegisterOtpUrl, {}, dispatch, getState)
      .then((res) => {
        const { code, message } = res.data;
        if (code == 200) {
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};

export const verifyOtp = (code) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(verifyOtpUrl, { otp: code }, dispatch, getState)
      .then((res) => {
        const { code, message, user } = res.data;
        if (code == 200) {
          dispatch({
            type: VERIFY_OTP_SUCCESS,
            payload: user,
          });
          dispatch({
            type: UPDATE_CURRENCY_SUCCESS,
            payload: user.currency,
          });
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
