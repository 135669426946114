import React from "react";
import DcaEntryView from "../dca-bot/DcaEntryView";
import { useTranslation } from "react-i18next";
import EntryConditionsView from "../EntryConditionsView";
import { useSelector } from "react-redux";
import { BotType } from "../../../../Const";

const EntryView = (props)=>{
    const {step} = props
    const {t} = useTranslation()
    const newBot = useSelector((state) => state.createBot.newBot);
    const getEntryView = ()=>{
        switch (newBot.botType) {
          case BotType.dca:
            return <DcaEntryView newBot={newBot} isEntry={true} t={t}/>
          default:
            return <EntryConditionsView newBot={newBot} isEntry={true} t={t}/>
        }
      }
    return(<>
     <div className="ba-asset-form-content">
        <div className="row gx-0 gy-3">
          {getEntryView()}
          <div className="col-lg-12">
              <div className="bas-asset-submit bas-asset-btn2">
                <button onClick={()=>step('prev')}>Previous</button>
                <button onClick={()=>step('next')}>Next</button>
              </div>
            </div>
          </div>
    </div>
    
    </>)
}

export default EntryView;